import React from 'react'
import {graphql, Link} from 'gatsby'
import get from 'lodash/get'
import BlogSplash from '../../components/BlogSplash'
import BlogSidebar from '../../components/BlogSidebar'
import './index.scss';
import Layout from "../../components/Layout";
import {GatsbyImage} from "gatsby-plugin-image";

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    return (
        <Layout>
          <section>
            <BlogSplash />
            <section className="container mb-4 blog">
              <div className="row">
                <div className="col-md-8">
                  {posts.map(({ node }) => {
                    const title = get(node, 'frontmatter.title') || node.fields.slug
                    return (
                        <Link to={node.fields.slug}>
                          <div className="row pb-4 align-middle">
                            <div className="col-lg-4 align-middle">
                              <Link to={node.fields.slug}>
                              <GatsbyImage image={node.frontmatter.image.childImageSharp.gatsbyImageData} className="card-img-top" />
                              </Link>
                            </div>
                            <div className="col-lg-8">
                              <Link
                                  to={node.fields.slug}
                                  className='blog-post-title__link'
                              >
                              </Link>
                              <div className="text-bold">
                                <Link
                                    to={node.fields.slug}
                                    className='blog-post-title__link'
                                >
                                  <h2 className="blog-post-title text-bold">
                                    {title}{" "}
                                  </h2>
                                </Link>
                                <Link
                                    to={node.fields.slug}
                                    className="blog-post-excerpt__link text-secondary"
                                >
                                  {node.excerpt}
                                </Link>
                                <p className="blog-post-meta text-muted mt-2">📅 {node.frontmatter.date}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                    );
                  })}
                </div>
                <BlogSidebar />
              </div>
            </section>
          </section>

        </Layout>
    );
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
  }
`
