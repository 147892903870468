import React from 'react';
import './index.scss';

const BlogSplash = () => (
  <div className="jumbotron jumbotron-fluid blog-splash bg-dark text-light">
    <div className="container text-center">
      <h1 className="font-weight-bold display-4">The Trunk</h1>
      <p className="lead text-secondary h5">Opinions, thoughts, and ramblings about being a programmer and human. From your friends at Browntree Labs</p>
    </div>
  </div>
)

export default BlogSplash;
