import React from 'react'
import './index.scss'
import { WorkTogetherCard } from '../WorkTogetherCard'
import { ConsultancyBlurb } from '../ConsultancyBlurb'

const BlogSidebar = () => (
  <aside className="col-md-4 blog-sidebar">
    <ConsultancyBlurb/>
    <WorkTogetherCard/>
  </aside>
);

export default BlogSidebar;
